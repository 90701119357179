import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaTachometerAlt, FaTable, FaMoneyBill, FaSignOutAlt,  FaBell, FaUser, FaCog, FaBars } from 'react-icons/fa';
import { LuLayoutDashboard } from "react-icons/lu";
import { GiBlackHoleBolas } from "react-icons/gi";
import { BsStars, BsList  } from "react-icons/bs";
import { AiOutlineSecurityScan } from "react-icons/ai";
import { MdQuiz, MdManageAccounts } from "react-icons/md";
import { signOut } from "firebase/auth"
import { collection, query, where, getDocs,  } from 'firebase/firestore';
import { db, auth } from '../Firebase_Config/firebase';
import { useEffect } from 'react';
import './sidebar.css'

const Sidebar = ({user}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dashboardOpen, setDashboardOpen] = React.useState(null)
  const [adminTrue, setAdminTrue] = React.useState(null)
 // Function to toggle render the sidebar 
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
 // Sign Out function 
  const handleSignOut =() => {
    signOut(auth)
    .then(() => console.log('Sign Out'))
    .catch((error) => console.log(error));
  }
  //Function accesses the user document to get informatation about certain condition like Admin or if assessments are complete to render certains tabs in the sidebar
  useEffect(() => {
    const fetchDash = async () => {
      try {
          const userId = getUserId();
          console.log('User ID:', userId); // Add this line for debugging

          // Fetch risk score
          const userDocQuery = query(collection(db, 'users'), where('userId', '==', userId));
          const userDocSnapshot = await getDocs(userDocQuery);

          if (!userDocSnapshot.empty) {
              const userDocRef = userDocSnapshot.docs[0].ref;
              const userDocData = userDocSnapshot.docs[0].data();
              const isAssComplete = userDocData.isAssessmentComplete || null;
              const isAdminTrue = userDocData.isAdmin || null;
              setDashboardOpen(isAssComplete)
              setAdminTrue(isAdminTrue)
              console.log(dashboardOpen)
              console.log(adminTrue)

          } else {
              console.log('User document not found for user ID:', userId);
          }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };
    fetchDash()
  }, [user]);
 // Fetches the UserID 
  const getUserId = () => {
    const user = auth.currentUser;
    return user.uid;
  };

  //HTML for the frontend 
  return (
    <>
      <div className={`sidebar ${isOpen ? "open" : ""}`}>
        <nav>
          <div className="logo-container">
            <img src="logo4.png" alt="LoKi Logo" />
          </div>
          <hr/>
          <ul>
            <li>
              <NavLink to="/Assessment" activeClassName="active">
                <MdQuiz className="icon" />
                <span>Assessment</span>
              </NavLink>
            </li>
            {dashboardOpen && 
              <li>
                <NavLink to="/dashboard" activeClassName="active">
                  <LuLayoutDashboard className="icon" />
                  <span>Dashboard</span>
                </NavLink>
              </li>
            }
             {adminTrue && 
              <li>
                <NavLink to="/AdminDashboard" activeClassName="active">
                  <MdManageAccounts className="icon" />
                  <span>Admin Dashboard</span>
                </NavLink>
              </li>
            }
            <li>
              <NavLink to="/ThreatIntelligence" activeClassName="active">
                <AiOutlineSecurityScan className="icon" />
                <span>Threat Intelligence</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/CoPilot" activeClassName="active">
                <BsStars className="icon" />
                <span>Threat Modelling Co-Pilot</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/AiDatabase" activeClassName="active">
                <BsStars className="icon" />
                <span>Ai Incident Database</span>
              </NavLink>
            </li>
            <li>
            <a
              className="sidebar-link" // Apply a class for the shared styling
              href="https://secai.ai/research" // Provide the link URL
              target="_blank" // Open in a new tab
            >
              <BsStars className="icon" />
              <span>SecAI</span>
            </a>
            </li>
            <li>
              <NavLink to="/AccountManagement" activeClassName="active">
                <FaUser className="icon" />
                <span>Account Management</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="" activeClassName="active">
                <FaSignOutAlt className="icon" />
                <span onClick={handleSignOut}>Sign Out</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
      <button className="toggle-btn" onClick={toggleSidebar}>
        <FaBars />
      </button>
    </>
  );
};

export default Sidebar;
